<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/24/outline'

import { useUiStore } from '@/stores'

const { showSidebar } = useUiStore()
</script>

<template>
  <button class="p-2.5 text-gray-700 -m-2.5 lg:hidden" @click="showSidebar">
    <span class="sr-only">Open sidebar</span>
    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
  </button>
</template>
