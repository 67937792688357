<script lang="ts" setup>
const { navigation } = usePlatformNavigation()
</script>

<template>
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:w-72 lg:flex lg:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex grow flex-col gap-y-8 overflow-y-auto bg-rfblue-800 px-6 py-8">
      <div class="h-16 flex shrink-0 flex-col gap-y-4">
        <img class="h-8 w-auto" src="~/assets/refloat-logo-with-text.svg" alt="Refloat company logo">
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li v-for="item in navigation" :key="item.name">
                <NuxtLink :to="item.link" class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6" :class="[item.current ? 'bg-rfblue-600 text-white' : 'text-gray-100 hover:text-white hover:bg-rfblue-600']">
                  <component :is="item.icon" class="h-6 w-6 shrink-0" :class="[item.current ? 'text-white' : 'text-gray-100 group-hover:text-white']" aria-hidden="true" />
                  {{ item.name }}
                </NuxtLink>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
