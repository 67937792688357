import { readonly } from 'vue'
import { HomeIcon } from '@heroicons/vue/24/outline'

export function usePlatformNavigation() {
  const navigation = [
    { name: 'Dashboard', link: '/dashboard', icon: HomeIcon, current: true },
  ]

  return {
    navigation: readonly(navigation),
  }
}
