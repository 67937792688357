<script lang="ts" setup>
import { TenantStripeDataSyncStatus } from '@refloat/supabase'
import PortalSidebarDynamic from '@/components/Sidebar/PortalSidebarDynamic.vue'
import PortalSidebarStatic from '@/components/Sidebar/PortalSidebarStatic.vue'
import PortalSidebarToggle from '@/components/Sidebar/PortalSidebarToggle.vue'

useHead({
  title: 'Refloat Platform',
})

const uiStore = useUiStore()
const tenantStore = useTenantStore()
const accountSetupStore = useAccountSetupStore()
const { hideSidebar } = uiStore
const { userActiveTenant } = storeToRefs(tenantStore)
const { currentStep } = storeToRefs(accountSetupStore)

const showStripeDataSyncInProgressBanner = computed(
  () => (
    userActiveTenant.value?.stripe_account_data_sync_progress === TenantStripeDataSyncStatus.SYNCING
    && currentStep.value?.id !== 'sync_stripe_data'
  ),
)
</script>

<template>
  <section>
    <PortalSidebarDynamic @nav-item-clicked="hideSidebar()" />

    <!-- Static sidebar for desktop -->
    <PortalSidebarStatic />

    <div class="lg:pl-72">
      <div class="sticky top-0 z-40 h-16 flex shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 lg:px-8 sm:px-6">
        <PortalSidebarToggle />

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 items-center self-stretch justify-center gap-x-4 text-center lg:gap-x-6">
          <h1 class="relative flex flex-1 text-xl font-400 tracking-wider">
            Refloat
          </h1>
        </div>
      </div>

      <main class="py-10">
        <div class="px-4 lg:px-8 sm:px-6">
          <slot />
        </div>

        <div v-if="showStripeDataSyncInProgressBanner" class="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
          <div class="pointer-events-auto ml-auto max-w-lg rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10 space-y-2">
            <p class="text-sm text-rfblue-900 font-500 leading-6 tracking-wide">
              Stripe data sync is in progress.
            </p>
            <p class="text-sm text-rfblue-900 font-normal leading-6">
              Please wait while we sync your data. In the meantime, check our integration instructions on how to set Refloat SDK on your platform.
            </p>
          </div>
        </div>
      </main>
    </div>
  </section>
</template>
